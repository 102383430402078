/* CSS DU MENU DE NAVIGATION */

.menu ul {
  list-style: none;
  padding-left: 20px;
}

.menu h1 {
  padding-left: 20px;
  padding-bottom: 40px;
  padding-top: 25px;
}

.menu h1 p{
  letter-spacing: 3px;
  font-weight: normal;
}

.menu a {
  text-decoration: none;
}

/* HEADER SEARCH */

.header .barreDeRecherche input {
  border: none;
  width: 98%;
  padding-left: 1%;
}

@media (max-width: 768px){
  //MENU
  nav ul {
    padding-left: 0.5rem!important;
  }
  .logoShokola {
    width: 20px;
    height: 20px;
  }
  nav .my-4{
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
  }
}